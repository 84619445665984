.fs-32 {
  font-size: 32px;
}

.fs-24 {
  font-size: 24px;
}

.fs-22 {
  font-size: 22px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

@media (max-width: 1440px) {
  .fs-32 {
    font-size: 28px;
  }

  .fs-24 {
    font-size: 21px;
  }

  .fs-22 {
    font-size: 19px;
  }

  .fs-20 {
    font-size: 17px;
  }

  .fs-18 {
    font-size: 16px;
  }

  .fs-16 {
    font-size: 14px;
  }

  .fs-14 {
    font-size: 12px;
  }

  .fs-12 {
    font-size: 11px;
  }
}
