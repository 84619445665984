.flex {
  display: flex;

  &.gap {
    gap: 28px;
  }

  &.gap-4 {
    gap: 4px;
  }

  &.gap-8 {
    gap: 8px;
  }

  &.gap-12 {
    gap: 12px;
  }

  &.gap-20 {
    gap: 20px;
    @media (max-width: 1024px) {
      gap: 14px;
    }
    @media (max-width: 768px) {
      gap: 10px;
    }
  }

  &.col {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }

  &.start {
    justify-content: flex-start;
    align-items: flex-start;

    &.center {
      align-items: center;
    }
  }

  &.between-center {
    justify-content: space-between;
    align-items: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.end {
    justify-content: flex-end;
  }

  &.equal-widths > * {
    flex: 1;
  }

  .flex-1 {
    flex: 1;
  }
}

.tab-nav {
  display: flex;
  align-items: center;
  gap: 24px;

  &.gap-12 {
    gap: 12px;
  }

  .loan {
    min-height: 110px;
    &.box {
      padding: 15px;
    }
  }

  &:not(.mini) > * {
    flex: 1;
  }

  &.mini > * {
    padding: 12px 24px;
  }

  button {
    font-weight: 700;

    span {
      color: #879297;
      display: block;
      margin-bottom: 6px;
    }

    &.active {
      border: 2px solid #009b54;
    }
  }
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-28 {
  margin-bottom: 28px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-12 {
  margin-top: 12px;
}

.fullwidth {
  width: 100%;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.no-scroll {
  overflow: hidden;
}

.box {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  padding: 24px;
  &.loan {
    padding: 15px 24px;
  }
  &.group-item {
    @media (max-width: 1280px) {
      padding: 24px 16px;
    }
    @media (max-width: 1100px) {
      padding: 24px 12px;
    }
  }
}

.box-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}

.info-row-with-actions:not(:last-of-type) {
  padding-bottom: 16px;
  border-bottom: 1.2px #abb3b7 solid;
  margin-bottom: 16px;
  &.loan {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
}

.info-row-with-actions .text {
  flex: 1;
}

.info-row-with-actions.active {
  background: rgba(215, 215, 216, 0.32);
}

.info-row-with-actions .text h3 {
  display: flex;
  align-items: center;
  gap: 4px;
}

.info-row-with-actions .actions button:last-child {
  margin-left: 16px;
  @media (max-width: 1100px) {
    margin-left: 12px;
  }
}

.info-row-with-actions .text h3 span {
  padding: 4px 8px;
}

img.profile-img {
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #879297;
}

.fullwidth {
  width: 100%;
}

.piedata-rounded {
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.pie-label {
  text-shadow: 0px 0px 5px #000000;
  font-size: 12px;
  text-align: center;
}

.piedata-value {
  color: #abb3b7;
}

.capitalize {
  text-transform: capitalize;
}

@media (max-width: 1440px) {
  .mb-32 {
    margin-bottom: 28px;
  }

  .mb-28 {
    margin-bottom: 26px;
  }

  .mb-20 {
    margin-bottom: 18px;
  }

  .mb-16 {
    margin-bottom: 14px;
  }

  .mb-12 {
    margin-bottom: 10px;
  }

  .mb-6 {
    margin-bottom: 4px;
  }

  .box-shadow {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  }
}
