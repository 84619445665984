* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #3d5059;
  letter-spacing: -1px;
  overscroll-behavior: none;
}

h2 {
  color: #9fa4a7;
  text-transform: uppercase;
  font-weight: 700;
}

h3 {
  font-weight: 600;
  font-size: inherit;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
}

input,
textarea,
select,
button {
  font: inherit;
  color: inherit;
  outline: none;
  letter-spacing: 0px;
}

textarea {
  resize: vertical;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  &:disabled {
    opacity: 0.5;
  }
}

input,
textarea,
select {
  width: 100%;
  padding: 13px 11px;
  border-radius: 12px;
  border: 1px solid #9fa4a7;

  &.mini {
    padding: 6px 8px;
  }

  &[readonly] {
    background: #f4f5f5;
    border-color: transparent;
  }
}

select {
  -webkit-appearance: none;
  appearance: none;

  background-image: url("../../assets/arrow_down.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: calc(100% - 6px) center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

hr {
  border: none;
  height: 0.5px;
  background-color: #d7d7d8;
}

@media (max-width: 1440px) {
  body {
    font-size: 16px;
  }
}
