.loan-repayment {
  font-weight: 600;

  input {
    padding: 12px 16px;
  }

  button {
    padding: 17px 16px;

    &.transparent {
      color: #627178;
    }
  }
}

.loan-checkbox {
  input {
    width: 20px;
  }
}

.approved-button {
  text-transform: capitalize;
  background: rgba(245, 221, 209, 0.56);
  border-radius: 8px;
  color: #f57e43;
  padding: 11px 30px;
  font-weight: 500;
}

.card {
  &__box {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    padding: 12px;
    cursor: pointer;
  }

  &__grid {
    display: grid;

    & > div {
      padding: 4px 0;
    }

    &.striped {
      & > div {
        padding: 4px 0 4px 12px;
      }
      div:nth-child(odd) {
        background: rgba(215, 215, 216, 0.32);
        border-radius: 4px;
      }
    }
  }
}

.statement-analysis {
  overflow: hidden;
  header {
    padding: 29px 32px;
    background-color: white;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  &__content {
    padding: 32px;
    overflow-x: scroll;
    @media (max-width: 1200px) {
      padding: 20px;
    }

    & > div {
      min-width: 650px;
      & > div:first-of-type {
        background: #ecf6f2;
        padding: 24px;
        box-shadow: none;
        color: #4a7f63;
        & h4 {
          color: #1f613e;
          padding-left: 0px;
        }
      }

      & > div {
        background: #ffffff;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
        border-radius: 12px;
        padding: 16px;
        & h4 {
          padding-left: 12px;
        }
      }
    }
  }
}

.repayment-amount-input {
  position: relative;
  & > input {
    padding-right: 70px !important;
  }
}

.repayment-amount-input-button {
  position: absolute;
  right: 10px;
  bottom: 5px;
  border: 1.5px solid #009b54;
  color: #009b54;
  padding: 4px 11px !important;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 1440px) {
  .loan-repayment {
    input {
      padding: 10px 14px;
    }

    button {
      padding: 13px 11px;
    }
  }
}
