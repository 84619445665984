.ads {
  &__heading {
    color: #9fa4a7;
    font-weight: 700;
  }
  &__table {
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    padding: 0 24px;
    margin-top: 16px;

    table {
      width: 100%;

      tr {
        border-bottom: 1px solid #abb3b7;
        padding: 22px 0;
        td {
          margin: 0 8px;
        }
        td:nth-of-type(3) {
          flex-grow: 1;
        }
      }

      tr:last-child {
        border: none;
      }
    }

    &-title {
      font-weight: 600;
      color: #3d5059;
      word-break: break-all;
    }
    &-subtitle {
      font-weight: 400;
      color: #879297;
    }
    &-newad {
      &-title {
        padding-top: 22px;
        font-weight: 600;
        color: #009b54;
        text-align: center;
        & > button {
          background: none;
          border: none;
        }
      }
      &-subtitle {
        padding-bottom: 22px;
        font-weight: 400;
        color: #879297;
        text-align: center;
      }
    }

    &-image {
      border-radius: 12px;
      overflow: hidden;
      object-fit: cover;
      width: 100px;
      height: 60px;
    }
  }

  &__modalcontent {
    padding: 40px 44px;
    max-width: 700px;

    p:nth-of-type(1) {
      font-weight: 700;
      color: #879297;
    }
    &-top {
      display: flex;
      margin-top: 16px;
      column-gap: 40px;
      &-selectimage {
        cursor: pointer;
        border: 1px dashed #3d5059;
        border-radius: 12px;
        padding: 46px 0;
        text-align: center;
        width: 200px;
        height: 200px;

        p:nth-of-type(1) {
          font-weight: 600;
          color: #3d5059;
          padding-bottom: 8px;
        }
        p:nth-of-type(2) {
          font-weight: 600;
          color: #3d5059;
          padding-bottom: 8px;
        }
        p:nth-of-type(3) {
          font-weight: 400;
          color: #879297;
          padding-bottom: 8px;
        }
      }
      &-inputs {
        flex-grow: 1;
      }
    }
    &-bottom {
      p:nth-of-type(1) {
        font-weight: 600;
        color: #3d5059;
      }
      &-previewpane {
        border: 1px solid #9fa4a7;
        border-radius: 12px;
        height: 200px;
        text-align: center;
        overflow: hidden;
        &-image {
          width: 100%;
          object-fit: contain;
          height: auto;
          max-height: 200px;
        }
      }
      &-save {
        border: none;
        width: 100%;
        background-color: #1f613e;
        border-radius: 8px;
        font-weight: 600;
        color: #ffffff;
        padding: 21px 0;
        margin-top: 20px;
        cursor: pointer;
        &:disabled {
          opacity: 0.5;
        }
      }
      &-cancel {
        font-weight: 600;
        border-radius: 8px;
        background: #ffffff;
        color: #009b54;
        cursor: pointer;
        width: max-content;
        border: none;
        width: 100%;
        padding: 21px 0;
        margin-top: 10px;
      }
    }
  }
}
