.checkbox {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: #009b54;
  cursor: pointer;

  &__box {
    width: 16px;
    height: 16px;
    border: 1.5px solid #009b54;
    border-radius: 4px;
  }
}
