.modal {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding-left: 260px;
  background: rgba(0, 0, 0, 0.3);
  overflow: scroll;
  z-index: 10;

  @media (max-width: 1440px) {
    padding-left: 240px;
  }

  @media (max-width: 1200px) {
    padding-left: 200px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    min-height: 100%;
  }

  &__content {
    width: 50%;
    max-width: 520px;
    margin: 40px auto;
    border-radius: 24px;
    background-color: white;
  }

  &__content__rightsheet {
    width: 40%;
    min-width: 400px;
    max-width: 660px;
    margin-left: auto;
    height: 100%;
    overflow: scroll;
    background-color: white;

    > div:first-child {
      text-align: right;
    }
  }

  &__content__notification {
    width: 40%;
    padding: 20px 25px;
    min-width: 400px;
    max-width: 520px;
    margin: auto;
    height: 100%;
    border-radius: 15px;
    background-color: white;

    @media (max-width: 1200px) {
      padding: 20px 15px;
      min-width: 350px;
    }

    > div:first-child {
      text-align: right;
    }
  }

  &__content__fullscreen {
    height: 100%;
    overflow: scroll;
    background-color: white;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .message__modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 20px;
    max-width: 400px;

    > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      text-align: center;
    }

    svg {
      width: 150px;
      height: 150px;
    }
  }
}

.account-holder-with-info-modal {
  padding: 24px 24px 50px;
}

.modal-header-with-back-btn {
  padding: 1rem 1rem;
  border-bottom: 0.5px solid #d7d7d8;
}

@media (max-width: 1440px) {
  .modal {
    &__content {
      width: 60%;
      border-radius: 18px;
    }

    .message__modal {
      max-width: 350px;

      svg {
        width: 120px;
        height: 120px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .modal {
    &__content {
      width: 80%;
    }
  }
}
@media (max-width: 1024px) {
  .modal {
    &__content {
      width: 85%;
    }
  }
}
