.admin-users {
  .tab-nav {
    flex: 1;
  }

  table {
    padding: 0;

    th {
      padding: 1.2rem 1rem;

      &:last-child {
        text-align: center;
      }
    }

    td {
      padding: 0.4rem 0.5rem;

      @media (max-width: 1280px) {
        padding: 0.4rem 0.2rem;
      }

      .btn:not(.back) {
        padding: 16px 6px;
        @media (max-width: 1280px) {
          padding: 16px 0;
        }
      }

      .btn:not(.back) {
        margin: 0 6px;
      }

      &:last-child {
        text-align: center;
      }
    }

    a {
      text-decoration: none;
    }
  }

  .modal__content {
    padding: 2.5rem 2rem 2rem;

    .admin-roles {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }
}
