.searchbox {
  &__container {
    position: relative;
    font-weight: 600;

    input {
      background: rgba(215, 215, 216, 0.32);
      border-radius: 12px;
      // padding: 19.5px 16px;
      border: none;
    }
  }
}

.in-place-popup {
  position: absolute;
  top: 100%;
  background: #ffffff;
  border-radius: 12px;
  width: 100%;
  min-width: max-content;
  overflow: hidden;
  z-index: 1;

  &:not(.alignEnd) {
    left: 0;
  }

  &.alignEnd {
    right: 0;
  }

  p {
    padding: 6px 14px;

    &:first-child {
      padding-top: 12px;
    }

    &:last-child {
      padding-bottom: 12px;
    }

    &:hover {
      background: rgba(215, 215, 216, 0.32);
    }
  }
}
