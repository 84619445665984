@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@import "base/reset";
@import "base/utilities";

@import "components/button";
@import "components/checkbox";
@import "components/colors";
@import "components/fonts";
@import "components/image_picker";
@import "components/input";
@import "components/modal";
@import "components/page_wrapper";
@import "components/page_title";
@import "components/table";
@import "components/uploaded-document-widgets";

@import "pages/admin-users";
@import "pages/admin-logs";
@import "pages/chat";
@import "pages/collections";
@import "pages/login";
@import "pages/loans";
@import "pages/loan-partners";
@import "pages/ads";
@import "pages/overview";
@import "pages/settings";
@import "pages/transactions";
@import "pages/notifications";
@import "pages/customers";
