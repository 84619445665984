.notifications {
  &__log {
    position: relative;
    &-blockade {
      position: absolute;
      inset: 0;
    }
  }

  .log-card {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 0.8rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    &.unread {
      background-color: #c4e3d5;

      h3 {
        color: #1f613e;
      }

      p,
      span {
        color: #759c87;
      }
    }

    > div {
      flex: 1;
    }

    h3 {
      color: #7d7989;
    }

    p,
    span {
      color: #b9b9ba;
    }
  }

  &__modalcontent {
    width: 80%;
    margin: auto;

    div {
      text-align: center;
      h3 {
        color: #1f613e;
      }

      p {
        color: #7d7989;
      }
    }

    p {
      color: #606062;
    }
  }
}
