.clickable {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  cursor: pointer;

  &.hover {
    border: 1px solid transparent;

    &:hover {
      border-bottom-color: #009b5577;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.btn {
  border: none;
  border-radius: 12px;
  font-weight: 600;
  background-color: white;
  cursor: pointer;

  &:not(.back) {
    padding: 16px;
  }

  &.back {
    display: flex;
    gap: 8px;
    align-items: center;
    align-self: flex-start;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.mini {
    border-radius: 8px;
    padding: 10px 13px;
    font-size: 14px;
    font-weight: normal;
  }

  &.big {
    padding: 23px 105px;
    font-size: 16px;
    font-weight: 700;
  }

  &.transparent {
    background-color: transparent;
    border-radius: 0;
  }

  &.light {
    border-radius: 8px;
    padding: 11px 30px;
  }

  &.primary {
    background-color: #009b54;
    color: #ffffff;

    &:hover {
      background-color: #009b54;
      color: white;
    }

    &.light {
      background-color: #c4e3d58f;
      color: #009b54;

      &.no-hover {
        cursor: text;
        &:hover {
          background-color: #c4e3d58f;
          color: #009b54;
        }
      }

      &:hover {
        background-color: #009b54;
        color: white;
      }
    }
  }

  &.danger {
    background-color: #ef6575;
    color: white;

    &:hover {
      background-color: #fc3833;
      color: white;
    }

    &.light {
      background-color: #f6cfce3d;
      color: #fc3833;

      &:hover {
        background-color: #fc3833;
        color: white;
      }
    }
  }

  &.outline {
    border: 1.5px solid #009b54;
    color: #009b54;

    &:hover {
      background-color: #009b54;
      color: white;
    }

    &.danger {
      background-color: white;
      border-color: #fc3833;
      color: #fc3833;

      &:hover {
        background-color: #fc3833;
        color: white;
      }
    }

    &.mini {
      border-width: 1px;
    }
  }
}

.btn.dark {
  background-color: #1f613e;
  color: white;
  border-radius: 8px;
}

@media (max-width: 1440px) {
  .btn.mini {
    padding: 8px 10px;
    font-size: 12px;
  }

  .btn.big {
    padding: 18px 80px;
    font-size: 14px;
  }
}
