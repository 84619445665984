.section {
  &__dropdown {
    padding: 12px 0;
    border-bottom: 1px solid #abb3b7;

    .dropdown-icon {
      margin-left: 6px;
      margin-top: 2px;
      transition: rotate 0.5s ease-in-out;

      &.rotate-180 {
        rotate: 180deg;
        margin-top: 3px;
      }
    }
  }
}

.loan-partner {
  &__row {
    position: relative;
  }
  &__options {
    position: absolute;
    right: 0;
    top: 55px;
    background: white;
    z-index: 2;
  }
}

.field {
  &__dropdown {
    max-height: 0;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    padding-left: 8px;
    padding-right: 8px;

    &.opened {
      max-height: max-content;
      & > div:first-of-type {
        padding-top: 8px;
      }
    }
  }
}

.dropdown__header {
  cursor: pointer;
}

.customer-application {
  & > div:first-of-type {
    & > div:last-of-type {
      border-bottom: none;
    }
  }
}

.field-values-con {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  word-break: break-all;
  margin: 5px 0;
}

.field-values-item {
  padding: 8px;
  background: #f2f2e9;
  font-size: 13px;
  color: #454444;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.field-values-item-remove {
  font-size: 10px;
  cursor: pointer;
}

.restriction-form {
  flex-wrap: wrap;
  & > div {
    min-width: 270px;
  }
}

.field-modal-input {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 14px;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    font-size: 14px;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    font-size: 14px;
  }
  &:-moz-placeholder { /* Firefox 18- */
    font-size: 14px;
  }
}