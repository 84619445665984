.chat {
  display: grid;
  grid-template-columns: 40% auto;
  column-gap: 24px;
  height: 100%;

  > * {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &-previewpane {
    border: 1px solid #9fa4a7;
    border-radius: 12px;
    height: 200px;
    text-align: center;
    overflow: hidden;
    &-image {
      width: 100%;
      object-fit: contain;
      height: auto;
      max-height: 200px;
    }
  }

  &__history {
    background: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    padding: 20px 24px;

    .searchbox-holder {
      display: flex;
      gap: 1rem;

      .searchbox__container {
        flex: 1;

        .searchconversations {
          background: rgba(208, 212, 214, 0.2);
          font-size: 13px;
        }
      }

      button {
        margin: 0;
      }
    }

    .conversations-list {
      flex: 1;
      overflow: auto;
      padding: 0 10px;
    }

    &-toptext {
      margin-top: 12px;
      font-weight: 600;

      > p:first-child {
        color: #9fa4a7;
        font-weight: 700;
      }

      .in-place-popup {
        > p:first-child {
          font-weight: 400;
          margin-bottom: 6px;

          &:hover {
            background: transparent;
          }
        }
      }
    }

    &-notification {
      color: #3d5059;
      column-gap: 20px;
      padding-top: 12px;
      padding-bottom: 16px;

      border-bottom: 1.2px solid #abb3b7;
      cursor: pointer;
      &:last-child {
        border: none;
      }
      &-left {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        h3:nth-of-type(1) {
          text-transform: capitalize;
          word-break: break-all;
        }

        h3:nth-of-type(2) {
          text-transform: uppercase;
          font-weight: 600;
          padding-top: 10px;
        }

        p {
          padding-top: 6px;
        }
      }
      span {
        color: #d0d4d6;
      }
    }
  }

  &__sendbulk {
    overflow: auto;

    p:nth-of-type(1) {
      font-weight: 600;
      color: #3d5059;
    }

    &-receipient {
      p:nth-of-type(1) {
        font-weight: 600;
        color: #3d5059;
      }

      &-options {
        display: flex;
        color: #009b54;
        stroke: #009b54;
        fill: #009b54;
        column-gap: 20px;
        max-width: 100%;
        flex-wrap: wrap;
        margin-top: 8px;
      }

      &-options2 {
        display: flex;
        color: #009b54;
        stroke: #009b54;
        fill: #009b54;
        column-gap: 20px;
        max-width: 100%;
        flex-wrap: wrap;
      }

      &-inputs {
        padding: 10px;
        display: grid;
        row-gap: 16px;

        .box {
          padding: 12px;

          & > div:first-of-type {
            cursor: pointer;
          }

          .dropdown-icon {
            margin-left: 6px;
            margin-top: 2px;
            transition: rotate 0.5s ease-in-out;

            &.rotate-180 {
              rotate: 180deg;
              margin-top: 3px;
            }
          }

          .flex > div {
            display: grid;
            row-gap: 8px;
          }
        }

        & > p {
          font-weight: 600;
          color: #3d5059;
        }

        &-selectimage {
          cursor: pointer;
          border: 1px dashed #3d5059;
          border-radius: 12px;
          padding: 24px 0;
          text-align: center;
        }
      }
      &-buttons {
        display: flex;
        width: max-content;
        margin: auto;
        column-gap: 24px;
        margin-top: 28px;
      }
    }
  }

  .chat-popup-msg {
    display: inline-block;
    transform: translateY(00.5rem);
    margin: 0 auto;
    z-index: 10;

    > span {
      background-color: #faece6;
      color: #f57e43;
      padding: 0.5rem;
      border-radius: 0.5rem;
    }
  }

  .profile-img {
    width: 40px;
    height: 40px;
  }

  .chat-msgs {
    flex: 1;
    padding: 1rem 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    word-break: break-all;

    .chat-msg {
      display: flex;
      gap: 0.5rem;

      > div {
        max-width: 60%;
      }

      &.left p {
        background-color: #d7d7d866;
        border-bottom-left-radius: 0;
      }

      &.right {
        justify-content: end;

        p {
          background-color: #009b54;
          color: white;
          border-bottom-right-radius: 0;
          text-align: right;
        }

        span {
          justify-content: end;
        }
      }

      .profile-img {
        width: 30px;
        height: 30px;
        margin-top: 0.5rem;
      }

      p {
        padding: 1rem 0.8rem;
        border-radius: 0.8rem;
      }

      span {
        color: #627178;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        margin-top: 0.25rem;

        svg {
          width: 12px;
          height: auto;
        }
      }
    }
  }

  input:disabled {
    background-color: #d0d4d6;
    border-color: #d0d4d6;
  }

  &__conversation {
    div {
      text-align: center;
      color: #3d5059;
      p:nth-of-type(1) {
        font-weight: 600;
      }
    }
  }
}

.batch {
  &__dropdown {
    max-height: 0;
    transition: all 0.5s ease-in-out;
    overflow: hidden;

    &.opened {
      max-height: max-content;
      & > div:first-of-type {
        padding-top: 8px;
      }
    }
  }
}

.message {
  &__dropdown {
    max-height: 0;
    display: grid;
    row-gap: 8px;
    padding: 0 8px;
    transition: all 0.5s ease-in-out;
    overflow: hidden;

    &.opened {
      max-height: max-content;
      & > div:first-of-type {
        padding-top: 8px;
      }
    }
  }
}
