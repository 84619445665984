/**
 General styles for all forms in the login page.
 */

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
}

.login-container {
  width: 100%;
  padding: 80px 100px;
  background-color: #009b540a;
  border-radius: 32px;
}

.login-row {
  background-color: white;
  border-radius: 20px;
  display: flex;
  overflow: hidden;
  min-height: 400px;
}

.login-col {
  flex: 1;
  padding: 65px 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-col > div {
  width: 100%;
}

.login-col .logo {
  width: 154px;
  aspect-ratio: auto;
}

.login-col.left {
  background: linear-gradient(180deg, #009b54 0%, #0b7041 100%);
  position: relative;
  z-index: 0;
}

.login-col.left::after {
  content: "";
  background-image: url(../../assets/login-illustration.svg);
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.login-col.right h1 {
  font-weight: 700;
  margin-bottom: 24px;
}

.form-field.has-icon {
  position: relative;
}

.login-form-field label {
  margin-bottom: 4px;
}

.login-form-field input[type="password"] {
  padding-right: 40px;
}

.form-field #form-field-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 12px;
  z-index: 10;
  user-select: none;
  cursor: pointer;
}

.forgot-pwd-btn {
  display: block;
  margin-left: auto;
  font-weight: 600;
  background-color: transparent;
  color: #009b54;
  border: none;
  margin-top: 4px;
  cursor: pointer;
}

#btn-back {
  margin-bottom: 8px;
}

.form-error {
  text-align: center;
  color: #fc3833;
  margin: 8px 0;
}

.form-success {
  text-align: center;
  margin: 8px 0;
  text-transform: capitalize;
}

/**
 Login OTP form styles.
 */

.login-col.right .login-otp h1 {
  margin-bottom: 6px;
}

.login-otp .login-form-field label {
  margin-bottom: 8px;
}

.login-otp .login-form-field input {
  border: none;
}

.login-otp .login-form-field .otp-box {
  position: relative;
  width: 100%;

  .blockade {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.otp-input-container {
  width: 100%;
  height: unset; /* use whatever height is necessary */
}

.otp-input-character {
  height: unset; /* use whatever height is necessary */
  border-radius: 12px;
  background-color: #ffffff;
  color: #3d5059;
  border: 1px solid #9fa4a7;
  line-height: 14px;
  font-size: 14px;
  padding: 22px 19px;
  outline: none;
}

.otp-input-character--inactive {
  color: #9fa4a7;
}

.otp-input-character--selected {
  color: #009b54;
}

@media only screen and (max-width: 1280px) {
  .login-col {
    padding: 65px 80px;
  }
}

@media only screen and (max-width: 1200px) {
  .login-col {
    padding: 65px;
  }
}

@media only screen and (max-width: 1024px) {
  .login-row {
    flex-direction: column;
  }

  .login-col {
    h1,
    p,
    label {
      text-align: center;
    }
  }

  :where(.vi__wrapper) {
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
  .login-container {
    padding: 0;
  }

  .login-col {
    padding: 65px 30px;
  }

  .login-col .logo {
    width: 64px;
  }
}
