.sidebar {
  position: fixed;
  width: 260px;
  height: 100%;
  background: linear-gradient(180deg, #009b54 0%, #0b7041 100%);
  overflow-x: auto;
  padding: 40px 40px 20px;
  font-weight: 600;

  @media (max-width: 1440px) {
    width: 240px;
  }

  @media (max-width: 1200px) {
    width: 200px;
    padding: 40px 20px 20px;
  }

  &__notifications {
    position: relative;

    span {
      background: #4a7f63;
      position: absolute;
      font-size: 11px;
      padding: 5px;
      border-radius: 50%;
      top: -10px;
      right: -5px;
      color: #ffffff;
      width: 20px;
      height: 20px;
    }
  }

  &__logo {
    margin-bottom: 60px;

    img {
      width: 100%;
      display: block;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
    color: rgba(255, 255, 255, 0.72);
    text-decoration: none;
    cursor: pointer;

    &.active {
      color: #ffffff;
    }

    span {
      margin-left: 24px;
    }
  }

  &__dropdown {
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
    margin-left: 48px;
    margin-bottom: 30px;
    list-style: none;

    &.opened200 {
      max-height: 200px; /* just for transition purposes */
    }

    &.opened400 {
      max-height: 400px; /* just for transition purposes */
    }

    .sidebar__link {
      margin-bottom: 0;
      margin-top: 20px;
    }
  }

  .logout {
    justify-content: center;
    background: rgba(255, 255, 255, 0.16);
    transition: all 0.2s ease-in-out;
    padding: 12px 0;
    margin-top: auto;
    margin-bottom: 15px;
    border-radius: 12px;

    span {
      margin-left: 0px;
      margin-right: 10px;
    }

    &:hover {
      color: white;
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.page-header-content {
  margin-left: 260px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media (max-width: 1440px) {
    margin-left: 240px;
  }

  @media (max-width: 1200px) {
    margin-left: 200px;
  }

  &.fullscreen {
    position: absolute;
    width: calc(100% - 260px);
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 1440px) {
      width: calc(100% - 240px);
    }

    @media (max-width: 1200px) {
      width: calc(100% - 200px);
    }

    .page-content {
      flex: 1;
    }
  }

  header {
    padding: 29px 32px;
    background-color: white;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;

    h1 {
      flex: 1;
      font-weight: 600;
    }

    .header-nav {
      display: flex;
      align-items: center;
      gap: 24px;

      input.search {
        width: 400px;
        padding: 16px;
        border: 1px solid #9fa4a7;
        border-radius: 12px;
        font-weight: 600;

        @media (max-width: 1440px) {
          width: 350px;
          padding: 12px;
        }

        @media (max-width: 1200px) {
          width: 200px;
        }

        @media (max-width: 1024px) {
          width: 120px;
        }

        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  .page-content {
    padding: 32px;
    overflow: hidden;
    flex: 1;

    @media (max-width: 1200px) {
      overflow-x: scroll;
      padding: 20px;
    }

    & > div {
      min-width: 650px;

      &.overview-summaries,
      &.overview-charts {
        min-width: 800px;
      }

      &.customers,
      &.admin-users,
      &.collections,
      &.loans,
      &.settings {
        min-width: 900px;
      }

      &.chat {
        min-width: 1000px;
      }
    }
  }
}

.modal-heading-subheading-buttons {
  padding: 60px;

  h1 {
    text-align: center;
    margin-bottom: 8px;
  }

  p {
    text-align: center;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;

    button {
      width: 240px;
      padding: 18px;
      border-radius: 8px;
      font-weight: 600;
    }
  }
}

@media (max-width: 1440px) {
  .sidebar {
    &__link span {
      margin-left: 18px;
    }

    &__dropdown {
      margin-left: 42px;
    }
  }

  .page-content {
    padding: 28px 32px;
  }

  .modal-heading-subheading-buttons {
    padding: 50px;

    .actions {
      gap: 40px;
      button {
        width: 180px;
        padding: 16px;
      }
    }
  }
}

.dropdown-icon {
  margin-left: 6px;
  margin-top: 2px;
  transition: rotate 0.5s ease-in-out;

  &.rotate-180 {
    rotate: 180deg;
    margin-top: 3px;
  }
}

.tooltip {
  position: relative;
  // display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #7c7c7c;
  color: #fff;
  text-align: center;
  padding: 6px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  width: 100%;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
