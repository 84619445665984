#image-picker {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:not(.btn) {
    padding: 12px 16px;
    border: 1px solid #9fa4a7;
    border-radius: 12px;
  }

  input {
    opacity: 0;
    border: none;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  button {
    width: unset;
    height: unset;
  }
}

.img-preview {
  width: 100%;
  max-width: 460px;
  height: 200px;
  border: 1px solid #9fa4a7;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1440px) {
  #image-picker:not(.btn) {
    padding: 10px 14px;
  }
}
