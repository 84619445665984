table {
  width: 100%;
  &.box {
    padding: 15px 28px;

    &.statement {
      td {
        padding: 8px;
      }
      td:nth-child(2) {
        max-width: 350px;
      }
    }

    tr {
      text-align: center;
    }
    th {
      padding-bottom: 16px;
      text-transform: uppercase;
      color: #9fa4a7;
    }
    td {
      border-top: 1.2px solid rgba(208, 212, 214, 0.6);
      padding: 16px 4px;
    }
  }
}

.search-filter-box {
  > div:first-child {
    font-weight: 700;
    margin: 40px 0 16px;

    > div:first-child {
      flex-grow: 1;
      max-width: 500px;
      margin-right: auto;
    }

    > div.flex {
      gap: 24px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    &.box {
      padding-top: 0;
    }

    th {
      text-transform: capitalize;
      color: #9fa4a7;
      padding: 32px 16px;
    }

    td {
      border-top: 1.2px solid rgba(208, 212, 214, 0.6);
      padding: 32px 0;
      font-weight: 600;
    }

    &.mini {
      th {
        padding: 24px 12px;
      }

      td {
        padding: 16px 0;
      }
    }
  }
}

@media (max-width: 1440px) {
  .search-filter-box {
    margin: 32px 0 14px;

    table {
      width: 100%;

      th {
        padding: 24px 12px;
      }

      td {
        padding: 24px 0;
      }

      &.mini {
        th {
          padding: 18px 9px;
        }

        td {
          padding: 12px 0;
        }
      }
    }
  }
}
