.admin-logs {
  .log-card {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 0.8rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    > div:first-child {
      flex: 1;
    }

    h3 {
      color: #7d7989;
    }

    p,
    span {
      color: #b9b9ba;
    }
  }
}
