.charcoal-60 {
  color: #879297;
}
.charcoal-80 {
  color: #627178;
}
.color-primary {
  color: #009b54;
}
.color-orange {
  color: #f57e43;
}
.gray-60 {
  color: #9c9c9d;
}
.tart-orange {
  color: #fc3833;
}
