.settings {
  hr {
    height: 1.2px;
    background-color: #abb3b7;
    margin: 28px 0;
  }

  h3 {
    margin-bottom: 4px;
  }

  .checkbox {
    gap: 16px;
    padding: 18px 24px;
    background-color: #dceee699;
    border-radius: 32px;

    > div {
      width: 24px;
      height: 24px;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .card-preview.box {
    padding: 32px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tab-nav button {
    color: #1f613e;
  }

  .tab-nav button.active {
    border: 1.5px solid #1f613e;
  }

  .integer-field {
    border: 1px solid #9fa4a7;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 10px;
    width: 195px;
    color: #627178;
  }

  .integer-field > button {
    width: 24px;
    height: 24px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .payday-loan-img-preview {
    max-width: unset;
    height: 188px;
  }

  .retail-loan-img-preview {
    height: 460px;
    margin-left: auto;
  }

  &.security-form {
    background-color: rgba(215, 215, 216, 0.12);
    padding: 24px 24px 48px;
    border-radius: 20px;

    > div {
      width: 80%;
      max-width: 600px;
      margin: 0 auto;
    }
  }

  .input-container {
    position: relative;
  }

  input[type="password"] {
    padding-right: 40px;
  }

  #form-field-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 12px;
    z-index: 10;
    user-select: none;
    cursor: pointer;
  }

  .gls {
    gap: 20px;
    text-align: center;

    @media (max-width: 1280px) {
      gap: 10px;
    }
  }
}

.css-166bipr-Input {
  & > input {
    min-width: 200px !important;
  }
}

@media (max-width: 1440px) {
  .settings {
    hr {
      margin: 26px 0;
    }

    .checkbox {
      gap: 12px;
      padding: 14px 20px;
      border-radius: 32px;
    }

    .integer-field {
      width: 165px;
    }

    &.security-form > div {
      max-width: 500px;
    }
  }
}

@media (max-width: 1280px) {
  .settings {
    .integer-field {
      width: 145px;
    }
  }
}

@media (max-width: 1080px) {
  .settings {
    .integer-field {
      width: 125px;
    }
  }
}
