.uploaded-doc-details {
  display: flex;
  align-items: center;
  padding: 1rem;

  &:first-of-type {
    padding-top: 0.5rem;
  }

  > div {
    flex-grow: 1;

    p:first-child {
      color: #009b54;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 600;
    }

    p:last-child {
      color: #759c87;
      font-size: 12px;
    }
  }

  > button {
    font-size: 12px;
    border-radius: 2rem;
    width: 6rem;
    padding: 0.5rem 0;
    text-transform: capitalize;
    color: #549dc6;
    background: rgba(84, 157, 198, 0.08);

    &.accepted {
      color: #009b54;
      background-color: rgba(132, 189, 163, 0.24);
    }

    &.rejected {
      color: #fc3833;
      background-color: rgba(239, 156, 153, 0.24);
    }
  }
}

.uploaded-doc-preview {
  > div {
    padding: 0 1rem;

    &.reject-uploaded-doc {
      padding: 1.5rem 1rem;
      border-top: 0.5px solid #abb3b7;
    }
  }

  .uploaded-doc-preview-img {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 15rem;
      margin: 0 auto;
      object-fit: contain;
    }

    svg {
      width: 80%;
      max-width: 200px;
      height: auto;
      stroke-width: 1px;
    }
  }

  .uploaded-doc-action-buttons {
    display: flex;
    gap: 1rem;

    a {
      border-color: #3d5059;
      color: #3d5059;
      text-align: center;
      text-decoration: none;
    }

    a,
    button {
      flex: 1 0;
    }
  }
}
