.successful-batch {
  overflow: hidden;

  header {
    padding: 29px 32px;
    background-color: white;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  &__content {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &>div {
      min-width: 900px;
    }
  }
}

.set-pin-content {
  display: flex;
  justify-content: space-between;
  gap: 6rem;
  padding: 1rem;

  @media (max-width: 1200px) {
    gap: 2rem;
  }

  >.form-container {
    flex-grow: 3;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.5rem 4rem;
    max-height: 480px;

    &>div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media (max-width: 1200px) {
      padding: 2.5rem 2rem;
    }

    label {
      margin-bottom: 4px;
    }
  }

  >.data-display {
    flex-grow: 4;
    flex-basis: 0;
    padding: 2.5rem 2rem;
    overflow: hidden;

    &:not(.empty-blocks) {
      >div:last-child {
        gap: 1rem;

        .info-row>div:last-child {
          text-align: right;
        }
      }

      .info-row {
        gap: 1rem;

        >div:first-child {
          flex: 3;
          color: #879297;
        }

        >div:last-child {
          flex: 4;
          font-weight: 600;
        }
      }
    }
  }

  .empty-blocks .info-row {
    gap: 1rem;

    >div {
      border-radius: 4px;
      width: 28%;
      height: 1rem;
      background: #cccccc;
      position: relative;
    }
  }

  .empty-blocks>div:last-child {
    gap: 1rem;

    .info-row {
      gap: 3rem;

      >div:first-child {
        flex: 3;
      }

      >div:last-child {
        flex: 4;
      }
    }
  }

  .fetching {

    &>div:last-of-type,
    &>div:nth-child(2) {
      &>div {
        &>div {
          border-radius: 4px;
          width: 28%;
          height: 1rem;
          background: #cccccc;
          position: relative;

          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            transform: translateX(-100px);
            background: linear-gradient(90deg,
                transparent,
                rgba(255, 255, 255, 0.2),
                transparent);
            animation: loading 1s infinite;
          }

          @keyframes loading {
            100% {
              transform: translateX(100%);
            }
          }
        }
      }
    }

    &>div:nth-child(2) {
      &>div {
        &>div {
          flex: 0 0 100px !important;
        }
      }
    }
  }

  .gray-box {
    background-color: #f4f5f5;
    padding: 1rem;
    border-radius: 12px;
  }
}

.validate-info-content {
  display: flex;
  justify-content: space-between;
  gap: 6rem;
  padding: 1rem;

  @media (max-width: 1200px) {
    gap: 2rem;
  }

  >.form-container {
    flex-grow: 3;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.5rem 4rem;
    max-height: 480px;

    &>div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media (max-width: 1200px) {
      padding: 2.5rem 2rem;
    }

    label {
      margin-bottom: 4px;
    }
  }

  >.data-display {
    flex-grow: 4;
    flex-basis: 0;
    padding: 2.5rem 2rem;
    overflow: hidden;

    &:not(.empty-blocks) {
      >div:last-child {
        gap: 1rem;

        .info-row>div:last-child {
          text-align: right;
        }
      }

      .info-row {
        gap: 1rem;

        >div:first-child {
          flex: 3;
          color: #879297;
        }

        >div:last-child {
          flex: 4;
          font-weight: 600;
        }
      }
    }
  }

  .empty-blocks .info-row {
    gap: 1rem;

    >div {
      border-radius: 4px;
      width: 28%;
      height: 1rem;
      background: #cccccc;
      position: relative;
    }
  }

  .empty-blocks>div:last-child {
    gap: 1rem;

    .info-row {
      gap: 3rem;

      >div:first-child {
        flex: 3;
      }

      >div:last-child {
        flex: 4;
      }
    }
  }

  .fetching {

    &>div:last-of-type,
    &>div:nth-child(2) {
      &>div {
        &>div {
          border-radius: 4px;
          width: 28%;
          height: 1rem;
          background: #cccccc;
          position: relative;

          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            transform: translateX(-100px);
            background: linear-gradient(90deg,
                transparent,
                rgba(255, 255, 255, 0.2),
                transparent);
            animation: loading 1s infinite;
          }

          @keyframes loading {
            100% {
              transform: translateX(100%);
            }
          }
        }
      }
    }

    &>div:nth-child(2) {
      &>div {
        &>div {
          flex: 0 0 100px !important;
        }
      }
    }
  }

  .gray-box {
    background-color: #f4f5f5;
    padding: 1rem;
    border-radius: 12px;
  }
}