.overview-summaries {
  display: flex;
  gap: 1rem;
  margin-bottom: 2.5rem;

  .box {
    flex: 1;
    padding: 1.2rem 1rem;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    text-decoration: none;

    h1 {
      color: #3d5059;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.1rem;
    }

    svg {
      align-self: center;
      width: 3rem;
      height: auto;
    }
  }
}

.overview-charts {
  display: flex;
  gap: 2rem;
  min-height: 400px;

  a {
    font-size: 12px;
    text-decoration: none;
  }

  > div:first-child {
    flex: 5;
    width: 0;
  }

  > div:last-child {
    flex: 4;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > * {
      flex: 1;
    }
  }

  .box {
    padding: 0 15px;

    .header {
      padding: 0.8rem 1.5rem;
      border-bottom: 2px solid #f0f0f0;
      letter-spacing: 0;

      &.with-amount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.4rem;
        div:last-of-type {
          text-align: right;
        }
      }
    }
  }
}

.chart {
  &__tooltip {
    background: white;
    border-radius: 5px;
    border: 1px solid black;
    padding: 5px;
  }
}
